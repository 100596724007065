import React from 'react'
import { RecoilRoot } from 'recoil'
import GoTop from './GoTop'
import SEO from './SEO'
import CookieConsent, { Cookies } from "react-cookie-consent";

const Layout = ({ children }) => {
    return (
        <RecoilRoot>
            <SEO />
            <CookieConsent
  location="bottom"
  buttonText="Accept Cookie"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#123c69" , fontSize: "14px"  }}
  buttonStyle={{ background: "#edc7b7", color: "#123c69", fontSize: "12px" }}
  expires={150}
>
By clicking “Accept Cookie”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing efforts. {" "}
  
</CookieConsent>
            {children}
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
        </RecoilRoot>
    )
}

export default Layout
