import React, { useState } from "react"
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import {Link} from 'gatsby'
import logo from "../../assets/images/minsony-logo.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";


const NavbarTwo = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);


   

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
       
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area navbar-style-two">
                <div className="tarn-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link 
                                to="/"
                                onClick={() => setCollapsed(true)} 
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo" width="150px" />
                            </Link>

                        

                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                               
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                        
                                <ul className="navbar-nav"> <li className="nav-item">
                                        <Link 
                                            to="/" 
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Home 
                                        </Link>
                                        

                                      </li>

                                      <li className="nav-item">
                                      <AnchorLink 
                                to="/#about"
                                onClick={() => setCollapsed(true)}
                                className="nav-link"
                               
                                >
                                   <span  onClick={() => setCollapsed(true)}> About </span>
                            </AnchorLink>
</li>
                                     
                                      <li className="nav-item">
                                      <AnchorLink 
                                to="/#rooms"
                                onClick={() => setCollapsed(true)}
                                className="nav-link"
                               
                                >
                                   <span  onClick={() => setCollapsed(true)}> Rooms </span>
                            </AnchorLink>
</li>

<li className="nav-item">
                                      <AnchorLink 
                                to="/#rates"
                                activeClassName="active"
                                            
                                className="nav-link"
                                >
                                    <span  onClick={() => setCollapsed(true)}> Rates </span>
                            </AnchorLink>
</li>     


<li className="nav-item">
                                      <AnchorLink 
                                to="/#faq"
                                onClick={() => setCollapsed(true)}
                                className="nav-link"
                               
                                >
                                   <span  onClick={() => setCollapsed(true)}> FAQ </span>
                            </AnchorLink>
</li>

<li className="nav-item">
                                      <AnchorLink 
                                to="/#find"
                                activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                className="nav-link"
                                >
<span  onClick={() => setCollapsed(true)}> Find Us </span>                            </AnchorLink>
</li>       



<li className="nav-item">
                                      <AnchorLink 
                                to="/#contact"
                                activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                className="nav-link"
                                >
<span  onClick={() => setCollapsed(true)}> Contact US </span>                            </AnchorLink>
</li>    
    


                                    
                                </ul>
                            
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        <Link 
                                            to="https://minsony.skedda.com/booking" 
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                             Book Room <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NavbarTwo
