import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Minsony - London treatment Rooms</title>
                <meta name="description" content="Minsony" />
                <meta name="og:title" property="og:title" content="Minsony"></meta>
                <meta name="twitter:card" content="Minsony"></meta>
                <link rel="canonical" href="https://minsony.com/"></link>
                <meta property="og:image" content="" />
            </Helmet>
        </div>
    )
}

export default SEO
