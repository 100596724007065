import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                
                  


                   

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p className="smalltext2">Copyright {currentYear}  <a target="_blank" href="https://minsony.com/" rel="noreferrer">Minsony</a>. All rights reserved. Company Reg No. 15126215</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li className="smalltext">
                                    <Link to="/minsony-terms">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li className="smalltext">
                                    <Link to="/minsony-terms">
                                        Terms & Conditions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

       
        </footer>
    );
}

export default Footer;